import React from 'react';
import LottieAnimation from '../atoms/LottieAnimation';

interface NetworkMap {
  title?: string;
  body?: string;
}

const NetworkMap: React.FC<NetworkMap> = ({
  title,
  body,
}) => {
  return (
    <section className="mb-20 md:my-32 ">
      <div className="py-20 mx-auto md:py-32 u-container bg-dark-grey">
        <div className="items-center justify-between px-6 md:flex md:px-24">
          <div className="max-w-lg mb-12 text-center md:mb-0 md:w-5/12 md:text-left">
            <h2 className="pt-2 mb-4 text-white lg:pt-0 u-h2">
              Access America’s largest c-store network
            </h2>
            <p
              className="text-lg text-white lg:text-xl"
            >Tap into SKU-level sales data from tens of thousands of independent convenience stores across the nation.</p>
            </div>
            <div className="md:w-1/2">
              <LottieAnimation lazyData="map-flow" play={true} />
            </div>
        </div>
      </div>
    </section>
  );
};

export default NetworkMap;