import { motion } from 'framer-motion';
import React, { useState } from 'react';
import * as Plus from 'images/plus.inline.svg';

interface AccordionPanelProps {
  title: string;
  body: string;
  transparent?: boolean;
}

const AccordionPanel: React.FC<AccordionPanelProps> = ({
  title,
  body,
  transparent,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={`${
        transparent
          ? 'border-b border-black border-opacity-10 last:border-transparent'
          : 'bg-tile pl-4 md:pl-6 mx-auto max-w-2xl'
      } py-5 pl-0 pr-4 md:pr-6 text-left max-w-lg mx-auto`}
    >
      <div>
        <button
          className="text-xl font-semibold w-full flex items-center justify-between focus:outline-none focus:ring-0 text-left"
          onClick={() => setOpen((isOpen) => !isOpen)}
        >
          <div className="mr-2 md:mr-6 w-11/12">{title}</div>
          <Plus
            className={`w-4 h-auto transition-transform duration-300 ${
              open ? 'transform rotate-45' : ''
            }`}
          />
        </button>
      </div>
      <motion.div
        className="overflow-hidden"
        initial={{ height: 0 }}
        animate={{ height: open ? 'auto' : 0 }}
        transition={{ type: 'tween', ease: 'easeInOut', duration: 0.3 }}
      >
        <div className="pt-5">{body}</div>
      </motion.div>
    </div>
  );
};

export default AccordionPanel;
