import { graphql } from 'gatsby';
import React from 'react';
import AccordionPanel from 'components/atoms/AccordionPanel';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import Hero from 'components/organisms/AnimationHero';
import NetworkMap from 'components/organisms/NetworkMap';
import Benefit from 'components/molecules/Benefit';
import Feature from 'components/molecules/Feature';
import BenefitsSection from 'components/organisms/BenefitsSection';
import CtaSection from 'components/organisms/CtaSection';
import FeaturesSection from 'components/organisms/FeaturesSection';
import TestimonialSection from 'components/organisms/TestimonialSection';
import Animation from '../../animations/insights-hero.json';


const InsightsCotent = ({
  hero,
  benefit,
  testimonial,
  benefits,
  features,
  cta,
}) => (
  <>
    <Hero
      className="insights-hero"
      breadcrumb="Insights"
      heading={hero?.title}
      content={hero?.description}
      button={{
        to: hero?.primaryCta?.url,
        label: hero?.primaryCta?.label,
      }}
      animationData={Animation}
    />

    <BenefitsSection>
      <Benefit
        title={benefit?.title}
        body={benefit?.body}
        image={benefit?.image}
        imageSide={benefit?.imageSide}
      />
    </BenefitsSection>

    <TestimonialSection
      quote={testimonial?.quote}
      ctaLabel="Read customer story"
      className="bg-dark-grey"
      inverted
      hasRectangle
    />

    <NetworkMap />

    <BenefitsSection>
      {benefits?.benefits?.map(
        ({ title, body, image, imageSide, accordion }, index) => (
          <Benefit
            key={index}
            title={title}
            body={body}
            image={image}
            imageSide={imageSide}
          >
            {accordion && (
              <div className="md:mt-8">
                {accordion.map(({ title, body }, index) => (
                  <AccordionPanel
                    key={index}
                    title={title}
                    body={body}
                    transparent
                  />
                ))}
              </div>
            )}
          </Benefit>
        ),
      )}
    </BenefitsSection>

    <FeaturesSection title={features?.title} body={features?.body}>
      {features?.features?.map(({ title, body, image }, index) => (
        <Feature key={index} title={title} body={body} image={image} />
      ))}
    </FeaturesSection>

    <CtaSection headline={cta?.headline} />
  </>
);

export const InsightsPreview = ({ entry }) => {
  return <InsightsCotent {...entry.get('data').toJS()} />;
};

const InsightsPage = ({
  data: {
    markdownRemark: { frontmatter },
  },
}) => {
  const { seo } = frontmatter;

  return (
    <Layout>
      <SEO
        title={seo?.title}
        description={seo?.description}
        openGraphImage={seo?.openGraphImage}
      />

      <InsightsCotent {...frontmatter} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query InsightsPageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/enterprise/insights/" }) {
      frontmatter {
        seo {
          title
          description
          openGraphImage
        }
        hero {
          title
          description
          primaryCta {
            label
            url
          }
        }
        benefit {
          title
          body
          image
          imageSide
        }
        testimonial {
          quote
        }
        benefits {
          benefits {
            title
            body
            image
            imageSide
            accordion {
              title
              body
            }
          }
        }
        features {
          title
          body
          features {
            title
            body
            image
          }
        }
        cta {
          headline
        }
      }
    }
  }
`;
export default InsightsPage;
